
const Gallery = () => {
    return (
        <>
            <main id="main">
                {/* Breadcumb */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h2>Gallery</h2>
                        <p>Executives</p>
                    </div>
                </div>

                {/* Executives */}
                <section id="trainers" className="trainers">
                    <div className="container" data-aos="fade-up">
                        <div className="row" data-aos="zoom-in" data-aos-delay="100">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/solo.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Solomon</h4> <span>President</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/innocent.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Innocent Samuel</h4> <span>Vice President 1</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/powela.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Powela</h4> <span>Vice President 2</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/ade.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Adefikayo</h4> <span>Secetary General</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/victory.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Victory Oseji</h4> <span>Financila Secetary</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/christa.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Christabel</h4> <span>Treasurer</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/oreva.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Oreva Eni Judit</h4> <span>Position</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/bright.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Bright Shemishere</h4> <span>Editor in Cheif</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/kevwe.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Oghenekevwe ThankGod</h4> <span>Programs Director</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/dopa.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Prosper</h4> <span>Membership Chairman</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/austin.jpeg" style={{ height: 'auto' }} className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Austine</h4> <span>Sport Director</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/no_name1.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Emediong</h4> <span>Vice LOC Chairman</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/m&m.jpeg" className="img-fluid" alt="" />
                                    <div className="member-content">
                                        <h4>Mr & Miss Nape</h4> <span>Learsi and Anthonia</span>
                                        <p></p>
                                        <div className="social">
                                            <a href="/#"><i className="bi bi-twitter"></i></a>
                                            <a href="/#"><i className="bi bi-facebook"></i></a>
                                            <a href="/#"><i className="bi bi-instagram"></i></a>
                                            <a href="/#"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <h1 className="text-center" style={{ fontWeight: '700', fontSize: '80px' }}>Coming Soon</h1> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Gallery