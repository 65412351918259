import Head from '../components/web.head';
import BodyExcos from '../components/gallery.excos.body';
import BodyEvents from '../components/gallery.events.body';
import Footer from '../components/web.footer';
import React, { Component } from 'react';

export class GalleryExcos extends Component {
    render() {
        return (
            <>
                <Head  />
                <BodyExcos /> 
                <Footer />
            </>
        )
    }
}

export class GalleryEvents extends Component {
    render() {
        return (
            <>
                <Head  />
                <BodyEvents />
                <Footer />
            </>
        )
    }
}