import Head from '../components/web.head';
import Body from '../components/contact.body';
import Footer from '../components/web.footer';
import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <>
                <Head  />
                <Body /> 
                <Footer />
            </>
        )
    }
}

export default Contact;