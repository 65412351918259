import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";

const navToogle = () => {
    document.querySelector('#navbar').classList.toggle('navbar-mobile')
    document.querySelector('em').classList.toggle('bi-list')
    document.querySelector('em').classList.toggle('bi-x')
}

const dropDown = () => {
    if (document.querySelector('#navbar').classList.contains('navbar-mobile')) {
        document.querySelector('.navbar .dropdown > a').nextElementSibling.classList.toggle('dropdown-active')
    }
}

const dropDown2 = () => {
    if (document.querySelector('#navbar').classList.contains('navbar-mobile')) {
        document.querySelector('.navbar .dropdown .gall > a').nextElementSibling.classList.toggle('dropdown-active')
        document.querySelector('.navbar .dropdown .gall > a').nextElementSibling.classList.toggle('active')
    }
}

const WebNavBar = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <>
            <nav id="navbar" className="navbar order-last order-lg-0">
                <ul>
                    <li className="home1">
                        <Link to={'/'} className={splitLocation[1] === "" ? "active" : ""}>
                            <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        <a href="//e-library.napepti.org.ng/" target={'_blank'}><span>E-Library</span></a>
                    </li>
                    <li className="dropdown" onClick={dropDown}>
                        <a href="javascript:void(0)" className={[splitLocation[1] === "gallery-excos" ? "active" : "", splitLocation[1] === "gallery-events" ? "active" : "", splitLocation[1] === "news" ? "active" : ""]}><span>Media</span><i className="bi bi-chevron-down"></i></a>
                        <ul>
                            <li className="dropdown gall" onClick={dropDown2}>
                                <a href="javascript:void(0)">Gallery <i className="bi bi-chevron-right"></i></a>
                                <ul>
                                    <li>
                                        <Link to={'/gallery-excos/'}>
                                            <span>Excos</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/gallery-events/'}>
                                            <span>Events</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/news/'}>
                                    <span>News</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to={'/about/'} className={splitLocation[1] === "about" ? "active" : ""}>
                            <span>About</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/contact/'} className={splitLocation[1] === "contact" ? "active" : ""}>
                            <span>Contact</span>
                        </Link>
                    </li>
                </ul>
                <em onClick={navToogle} className="bi bi-list mobile-nav-toggle"></em>
            </nav>
        </>
    )
}

export default WebNavBar