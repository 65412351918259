import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';


const Footer = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleSubmit = event => {
        setSuccess(false);
        event.preventDefault();
        if (!mailformat.test(email)) {
            setError('Invalid email address');
            document.getElementById('sub_email').focus();
        } else {
            setError(false);
            const submit = document.getElementById('submit');
            const prevText = submit.innerHTML;
            submit.innerHTML = `<img src="/assets/images/loader.gif" style="width: 20px; height: 20px" /> &nbsp; Submiting...`
            submit.disabled = true;
            fetch(`${process.env.REACT_APP_SERVER_URL}`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'subscribe',
                    subscriber: email,
                })
            }).then((response) => response.json())
            .then((data) => {
                if (data.status == 200) {
                    setSuccess(data.message)
                    setEmail('');
                    submit.disabled = false;
                    submit.innerHTML = prevText;
                } else {
                    setError(data.message)
                    submit.disabled = false;
                    submit.innerHTML = prevText;
                }
            })
        }
    }

    return (
        <>
            <footer id={'footer'}>
                <div className={'footer-top'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-lg-4 col-md-4 footer-contact'}>
                                <h3>Nape PTI</h3>
                                <p> PMB: 20 Effurun<br /> Delta State Nigeria <br /><br /> <strong>Phone:</strong> +1 5589 55488 55<br /> <strong>Email:</strong> <a href={'mailto:info@napepti.org.ng'} className={''}>info@napepti.org.ng</a><br /></p>
                            </div>
                            <div className={'col-lg-4 col-md-4 footer-links'}>
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className={'bx bx-chevron-right'}></i> 
                                        <Link to={'/'}>
                                            <span>Home</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <i className={'bx bx-chevron-right'}></i>
                                        <Link to={'/about/'}>
                                            <span>About us</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <i className={'bx bx-chevron-right'}></i>
                                        <Link to={'/gallery-events/'}>
                                            <span>Gallery</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <i className={'bx bx-chevron-right'}></i> 
                                        <Link to={'/contact/'}>
                                            <span>Contact</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={'col-lg-4 col-md-4 footer-newsletter'}>
                                <h4>Join Our Newsletter</h4>
                                <p>Receive updates on our latest publications via mail</p>
                                <form method={'POST'}> 
                                    <input type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} id="sub_email" name={'email'}/>
                                    <button type={'submit'} id="submit" onClick={handleSubmit}>Subscribe</button>
                                </form>
                                <small className={(!error) ? 'd-none' : 'text-danger'}>{error}</small>
                                <small className={(!success) ? 'd-none' : 'text-success'}>{success}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'container d-md-flex py-4'}>
                    <div className={'me-md-auto text-center text-md-start'}>
                        <div className={'copyright'}> &copy; Copyright <strong><span>NAPE PTI</span></strong>. All Rights Reserved</div>
                        <div className={'credit'}> Powered by <a href={'//elitecodec.com.ng'} target={'_blank'}>Elite Codec Inc</a></div>
                    </div>
                    <div className={'social-links text-center text-md-right pt-3 pt-md-0'}> <a href={'/#'} className={'twitter'}><i className={'bx bxl-twitter'}></i></a> <a href={'/#'} className={'facebook'}><i className={'bx bxl-facebook'}></i></a>
                        <a href={'/#'} className={'instagram'}><i className={'bx bxl-instagram'}></i></a> <a href={'/#'} className={'google-plus'}><i className={'bx bxl-skype'}></i></a> <a href={'/#'} className={'linkedin'}><i className={'bx bxl-linkedin'}></i></a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;