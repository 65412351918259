import React, { Component } from "react";
import axios from "axios";

const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            type: false,
            result: false,
            nameError: false,
            emailError: false,
            subjectError: false,
            messageError: false
        }
    }
    
    ///////// SEND DATA TO SERVER /////////////
    SendMail = event => {
        event.preventDefault();
        this.setState({ result: false})
        if (this.state.name == '') {
            document.getElementById('name').focus()
            this.setState({ nameError: 'Fullname is required' });
            document.getElementById('name').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else if (!mailformat.test(this.state.email)) {
            document.getElementById('email').focus()
            this.setState({ emailError: 'Invalid email address' });
            document.getElementById('email').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else if (this.state.subject == '') {
            document.getElementById('subject').focus()
            this.setState({ subjectError: 'Subject is required' });
            document.getElementById('subject').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else if (this.state.message == '') {
            document.getElementById('message').focus()
            this.setState({ messageError: 'Message is required' });
            document.getElementById('message').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else {
            this.setState({ nameError: false })
            this.setState({ emailError: false })
            this.setState({ subjectError: false })
            this.setState({ messageError: false })
            document.getElementById('name').style.boxShadow = ''
            document.getElementById('email').style.boxShadow = ''
            document.getElementById('subject').style.boxShadow = ''
            document.getElementById('message').style.boxShadow = ''
            const submit = document.querySelector('button[type=submit]');
            const prevText = submit.innerText
            submit.disabled = true;
            submit.innerHTML = `<img src="/assets/images/loader.gif" style="width: 20px; height: 20px" /> &nbsp; Submiting...`
            setTimeout(() => {
                // axios.post(`${process.env.REACT_APP_SERVER_URL}`, {...this.state}).then((response) => {
                //     if (response.data.status == 200) {
                //         this.setState({ name: '' })
                //         this.setState({ email: '' })
                //         this.setState({ subject: '' })
                //         this.setState({ message: '' })
                //         submit.innerHTML = prevText;
                //         submit.disabled = false;
                //         this.setState({ type: 'text-success', result: response.data.message });
                //     } else {
                //         submit.innerHTML = prevText;
                //         submit.disabled = false;
                //         this.setState({ type: 'text-danger', result: response.data.message ? response.data.message : 'Unable to send message. Try again'});
                //     }
                // })
            }, 3000);
        }
    }

    ////////// SET VALUES VIA STATE ////////////
    onInputChange = event => {
        const {name, value} = event.target;
        this.setState({
            ...this.state,   
            [name]: value
        })
    }
    
    render() {
        return (
            <>
                <main id="main">
                    {/* Breadcumb */}
                    <div className="breadcrumbs" data-aos="fade-in">
                        <div className="container">
                            <h2>Contact Us</h2>
                            <p></p>
                        </div>
                    </div>
    
                    {/* Contact Section */}
                    <section id="contact" className="contact">
                        <div data-aos="fade-up"> <iframe title="map" style={{ border: '0', width: '100%', height: '350px' }} src="https://maps.google.com/maps?q=Petroleum%20Training%20Institute%20Warri&t=&z=13&ie=UTF8&iwloc=&output=embed" allowFullScreen></iframe></div>
                        <div className="container" data-aos="fade-up">
                            <div className="row mt-5">
                                <div className="col-lg-4">
                                    <div className="info">
                                        <div className="address"> <i className="bi bi-geo-alt"></i>
                                            <h4>Location:</h4>
                                            <p>P.M.B 20 Effurun Delta State, Nigeria</p>
                                        </div>
                                        <div className="email"> <i className="bi bi-envelope"></i>
                                            <h4>Email:</h4>
                                            <p><a href="mailto:info@napepti.org.ng">info@napepti.org.ng</a></p>
                                        </div>
                                        <div className="phone"> <i className="bi bi-phone"></i>
                                            <h4>Call:</h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 mt-5 mt-lg-0">
                                    <form method="POST" role="form" onSubmit={this.SendMail} className="php-email-form">
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input type="text" name="name" value={this.state.name} onChange={this.onInputChange} className="form-control" id="name" placeholder="Your Name" />
                                                <small className={this.state.nameError !== false ? 'text-danger' : 'd-none'}>{this.state.nameError}</small>
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <input type="text" className="form-control" value={this.state.email} onChange={this.onInputChange} name="email" id="email" placeholder="Your Email" />
                                                <small className={this.state.emailError !== false ? 'text-danger' : 'd-none'}>{this.state.emailError}</small>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" value={this.state.subject} onChange={this.onInputChange} name="subject" id="subject" placeholder="Subject" />
                                            <small className={this.state.subjectError !== false ? 'text-danger' : 'd-none'}>{this.state.subjectError}</small>
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control" id="message" name="message" value={this.state.message} onChange={this.onInputChange} rows="5" placeholder="Message" ></textarea>
                                            <small className={this.state.messageError !== false ? 'text-danger' : 'd-none'}>{this.state.messageError}</small>
                                        </div>
                                        <div className="mt-3">
                                            <div className={this.state.type}>{this.state.result}</div>
                                        </div>
                                        <div className="text-center mt-3"><button type="submit">Send Message</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        )
    }
}

export default Contact;