import React from "react";
import { Modal } from "react-bootstrap";

const RModal = (props) => {
    const { show, footer, handleClose, size, modalTitle } = props;
    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard="false"
        centered onClose={handleClose} size={size} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
                <p className="btn-modal-close" onClick={() => handleClose()}>
                    <i className="fa fa-times text-danger"></i>
                </p>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                {props.footer}
            </Modal.Footer>   
        </Modal>
    );
};

export default RModal;