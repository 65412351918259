
const About = () => {
    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src={'/assets/images/about-1.JPG'} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h3>ABOUT NAPE NIGERIA</h3>
                            <p className="fst-italic">The Nigerian Association of Petroleum Explorationists started as Society of Geologist and Geophysicist, Lagos (SGGL) from the idea of a young man named Akomeno Oteri, who felt it was desirable to have such a professional society for furthering both social and technical interaction among professional colleagues. The idea was developed after he attended one of the monthly meetings of the Society of Exploration Geophysicists (SEG) in Dallas USA.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;