import React, { Component, useState } from "react";
import WebNavBar from './web.NavBar';
import Modal from './modal';
import axios from 'axios';

const mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class WebHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            name: '',
            email: '',
            mat_no: '',
            nameError: false,
            emailError: false,
            matNoError: false,
            serverMsg: false,
            data: false
        }
        this.modal = this.openModal.bind(this)
    }

    handleSubmit = event => {
        event.preventDefault();
        const matLen = (this.state.mat_no.split('/')).length - 1;
        const matSplit = this.state.mat_no.split('/');
        if (this.state.name == '' || this.state.name == null || this.state.name == undefined) {
            document.getElementById('gname').focus()
            this.setState({ nameError: 'Fullname is required' });
            document.getElementById('gname').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else if (!mailformat.test(this.state.email)) {
            document.getElementById('email').focus()
            this.setState({ emailError: 'Invalid Email Address' })
            document.getElementById('email').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else if (!this.state.mat_no.includes('/') || matLen < 3 || !matSplit[0].includes('.') || !matSplit[0].match("(?=(.*?\\d){2})[a-zA-Z]{1,}") || !/^[a-zA-Z]*$/.test(matSplit[1]) || !/^[a-zA-Z]*$/.test(matSplit[2]) || !/^[0-9]*$/.test(matSplit[3])) {
            document.getElementById('mat_no').focus()
            this.setState({ matNoError: 'Matric number invalid' })
            document.getElementById('mat_no').style.boxShadow = 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6)'
        } else {
            this.setState({ nameError: false })
            this.setState({ emailError: false })
            this.setState({ matNoError: false })
            document.getElementById('gname').style.boxShadow = ''
            document.getElementById('email').style.boxShadow = ''
            document.getElementById('mat_no').style.boxShadow = ''
            const submit = document.getElementById('g_submit')
            const prevText = submit.innerText
            submit.innerHTML = `<img src="/assets/images/loader.gif" style="width: 20px; height: 20px" /> &nbsp; Submiting...`
            fetch(`${process.env.REACT_APP_SERVER_URL}`, {
                method: 'POST',
                body: JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    mat_no: this.state.mat_no,
                    action: 'grant_access'
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.status == 200) {
                    this.setState({ name: '' })
                    this.setState({ email: '' })
                    this.setState({ mat_no: '' })
                    document.getElementById('msg').classList.add('d-none')
                    this.setState({ data: <div className="text-center"><h3 className="text-success">{ data.message }</h3></div> })
                } else {
                    this.setState({ data: <div className="text-center"><h3 className="text-danger">{ data.message }</h3></div> })
                    submit.innerHTML = prevText;
                }
            })
        }
    }

    openModal = () => {
        this.setState({ show: true })
        this.setState({ data: false })
        this.setState({ nameError: false })
        this.setState({ emailError: false })
        this.setState({ matNoError: false })
    }

    render() {
        return (
            <>
                <header id="header" className="fixed-top">
                    <div className="container d-flex align-items-center">
                        <h1 className="logo me-auto"><a href="/">Nape PTI</a></h1>
                        <WebNavBar />
                        <a onClick={() => this.openModal()} style={{ cursor: 'pointer' }} className="get-started-btn">Get Access</a>
                    </div>
                </header>
                <Modal
                    show={this.state.show}
                    handleClose={() => this.setState({ show: false })}
                    handleShow={() => this.setState({ show: true })}
                    modalTitle="Get Access"
                >
                    {this.state.data}
                    <div id="msg" className="contact">
                        <form method="POST" className="php-email-form">
                            <div className="mb-3 form-group">
                                <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder="Enter Your Fullname" id="gname" />
                                <small className={this.state.nameError !== false ? 'text-danger' : 'd-none'}>{this.state.nameError}</small>
                            </div>
                            <div className="mb-3 form-group">
                                <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Enter Email Address" id="email" />
                                <small className={this.state.emailError !== false ? 'text-danger' : 'd-none'}>{this.state.emailError}</small>
                            </div>
                            <div className="mb-3 form-group">
                                <input type="text" className="form-control" value={this.mat_no} onChange={(e) => this.setState({ mat_no: e.target.value})} placeholder="Enter Your Full Matric Number" id="mat_no" />
                                <small className={this.state.matNoError !== false ? 'text-danger' : 'd-none'}>{this.state.matNoError}</small>
                            </div>
                            <div className="text-center">
                                <button type='submit' style={{ width: '100% !important' }} onClick={this.handleSubmit} className='btn btn-primary' id="g_submit"> Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </>
        )
    }
}

export default WebHeader