

const Gallery = () => {
    return (
        <>
            <main id="main">
                {/* Breadcumb */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h2>Gallery</h2>
                        <p>Events</p>
                    </div>
                </div>

                {/* Executives */}
                <section id="trainers" className="trainers">
                    <div className="container" data-aos="fade-up">
                        <div className="row" data-aos="zoom-in" data-aos-delay="100">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/group3.jpeg" className="img-fluid" alt="" />
                                    {/* <div className="member-content">
                                        <h4>Mr & Miss Nape</h4> <span>Learsi and Anthonia</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/excos.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/group1.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/school.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/same.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/group2.jpeg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9115.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9187.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9405.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9119.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9222.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9354.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9359.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9137.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="member">
                                    <img src="/assets/images/BAZ_9410.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            {/* <h1 className="text-center" style={{ fontWeight: '700', fontSize: '80px' }}>Coming Soon</h1> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Gallery