import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Count = () => {
    const [students, setStudents] = useState(0);
    const [materials, setMaterials] = useState(0);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}?action=studentCount`).then((response) => {
            if (response.status == 200) {
                setStudents(response.data.count);
            } else {
                console.log('An error occured');
            }
        })
        axios.get(`${process.env.REACT_APP_SERVER_URL}?action=materialCount`).then((response) => {
            if (response.status == 200) {
                setMaterials(response.data.count)
            } else {
                console.log('An error occured');
            }
        })
    })

    return (
        <>
            <section id="counts" className="counts section-bg">
                <div className="container">
                    <div className="row counters">
                        <div className="col-lg-4 col-7 text-center"> <span data-purecounter-start="0"
                                data-purecounter-end={students} data-purecounter-duration="1" className="purecounter"></span>
                            <p>Students</p>
                        </div>
                        <div className="col-lg-4 col-7 text-center"> <span data-purecounter-start="0" data-purecounter-end={materials}
                                data-purecounter-duration="1" className="purecounter"></span>
                            <p>Materials</p>
                        </div>
                        <div className="col-lg-4 col-7 text-center"> <span data-purecounter-start="0" data-purecounter-end="4"
                                data-purecounter-duration="1" className="purecounter"></span>
                            <p>Events</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Count