import React, { Component } from "react";
import Counter from './web.count';
import WhoWeAre from './index.whoWeAre';

const About = () => {
    return (
        <>
            <main id="main">
                {/* Breadcumb */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h2>About Us</h2>
                        <p>Founded in 2002 whose vision is to bridge the gap between academia and industry</p>
                    </div>
                </div>

                {/* About Section */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100"><img src={'/assets/images/about-2.JPG'} className="img-fluid" alt="" /></div>
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                                <h3>ABOUT NAPE NIGERIA</h3>
                                <p className="fst-italic">The Nigerian Association of Petroleum Explorationists started as Society of Geologist and Geophysicist, Lagos (SGGL) from the idea of a young man named Akomeno Oteri, who felt it was desirable to have such a professional society for furthering both social and technical interaction among professional colleagues. The idea was developed after he attended one of the monthly meetings of the Society of Exploration Geophysicists (SEG) in Dallas USA.</p>
                                <p>The name later changed to the Nigerian Association of Petroleum Explorationists (NAPE) to accommodate colleagues in other parts of the country. The society which started with only 10 people attending the inaugural meeting at Federal Palace Hotel in August 1975 now has 12,073 individual members and 173 supporting corporate members. </p>
                                {/* <ul>
                                    <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat.</li>
                                    <li><i className="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate
                                        velit.</li>
                                    <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda
                                        mastiro dolore eu fugiat nulla pariatur.</li>
                                </ul>
                                <p> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate</p> */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Counter */}
                <Counter />
                <WhoWeAre />

                {/* Testimonies */}
                <section id="testimonials" className="testimonials">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Testimonials</h2>
                            <p>What they are saying</p>
                        </div>
                        <div className="testimonials-slider swiper" id="testimonials-slider" data-aos="fade-up" data-aos-delay="100">
                            <div className="swiper-wrapper">
                                {
                                    [1, 2, 3, 4, 5].map((i) => (
                                        <div key={i} className="swiper-slide">
                                            <div className="testimonial-wrap">
                                                <div className="testimonial-item">
                                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                                                    <h3>Bright</h3>
                                                    <h4>Student</h4>
                                                    <p> <i className="bx bxs-quote-alt-left quote-icon-left"></i> Been part of the <strong>NIGERIAN ASSOCIATION OF PETROLEUM EXPLORATIONIST</strong> has exposed me to alot in the field of Petroleum geology. <i className="bx bxs-quote-alt-right quote-icon-right"></i></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="swiper-pagination" id="swiper-pagination"></div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default About;