
const Publications = () => {
    return (
        <>
            <section id="popular-courses" className="courses">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Media</h2>
                        <p>Recent Publications</p>
                    </div>
                    <div className="row" data-aos="zoom-in" data-aos-delay="100">
                        {/* {
                            [1, 2, 3].map((i) => (
                                <div key={i} className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                    <div className="course-item"> <img src="assets/img/course-1.jpg" className="img-fluid" alt="..." />
                                        <div className="course-content">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h4>Web Development</h4>
                                                <p className="price">$169</p>
                                            </div>
                                            <h3><a href="course-details.html">Website Design</a></h3>
                                            <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae
                                                dolores dolorem tempore.</p>
                                            <div className="trainer d-flex justify-content-between align-items-center">
                                                <div className="trainer-profile d-flex align-items-center"> <img src="assets/img/trainers/trainer-1.jpg" className="img-fluid" alt="" />
                                                    <span>Antonio</span></div>
                                                <div className="trainer-rank d-flex align-items-center"> <i
                                                        className="bx bx-user"></i>&nbsp;50 &nbsp;&nbsp; <i
                                                        className="bx bx-heart"></i>&nbsp;65</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } */}
                        <h3 className="text-center">No Recent Publications</h3>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Publications;