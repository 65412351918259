import About from './index.about';
import Counter from './web.count';
import Publications from './publications';
import WebHeader from './web.head';

class HomeBody extends WebHeader {
    constructor() {
        super();
    }
    
    render() {
        return (
            <>
                <main id="main">
                    <section id="hero" className="d-flex justify-content-center align-items-center">
                        <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">
                            <h1>NAPE PTI Chapter,<br />Leading Tomorrow</h1>
                            <h2>Founded 2002 whose vision is to bridge the gap between academia and industry</h2>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.modal()} className="btn-get-started">Get Access</a>
                        </div>
                    </section>
                    <About />
                    <Counter />
                    <Publications />
                </main>
            </>
        )
    }
}

export default HomeBody;