import React, { Component } from 'react';
import { BrowserRouter, BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';

///////////////// PAGES /////////////////////
import Home from './pages/index';
import About from './pages/about';
import { GalleryExcos, GalleryEvents} from './pages/gallery';
import Contact from './pages/contact';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <Router> */}
          <Routes>
            <Route path='/'>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="gallery-excos" element={<GalleryExcos />} />
              <Route path="gallery-events" element={<GalleryEvents />} />
              <Route path='contact' element={<Contact />} />
              {/* <Route element={< />} /> */}  
            </Route>
          </Routes>
        {/* </Router> */}
      </BrowserRouter>
    )
  }
}

export default App;
