import Head from '../components/web.head';
import Body from '../components/about.body';
import Footer from '../components/web.footer';
import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <>
                <Head  />
                <Body /> 
                <Footer />
            </>
        )
    }
}

export default About;