
const WhoWeAre = () => {
    return (
        <>
            <section id="why-us" className="why-us">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-stretch">
                            <div className="content">
                                <h3>Who We Are</h3>
                                <p><strong>NAPE PTI</strong> is student chapter that was founded in 2002 whose vision is to bridge the gap between academia and industry. It also aims at integrating social and technical interactions among young professionals.</p>
                            </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-xl-6 d-flex align-items-stretch">
                                        <div className="icon-box mt-4 mt-xl-0"> <i className="bx bx-trip"></i>
                                            <h4>Mission</h4>
                                            <p>To promote the study and practice of petroleum geosciences for the benefit of members and other stakeholders.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 d-flex align-items-stretch">
                                        <div className="icon-box mt-4 mt-xl-0"> <i className="bx bx-bullseye"></i>
                                            <h4>Vision</h4>
                                            <p>To be the preferred professional petroleum geosciences Association with a global reach. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhoWeAre;